<template>
    <div id="secure" v-if="partner">
        <h1>Partner Details</h1>
        <h3>(ID: {{ partner.id }})</h3>

        <table v-if="partner" id="table">
            <tr>
                <th>Vorname</th>
                <td>{{ partner.firstName }}</td>
            </tr>
            <tr>
                <th>Nachname</th>
                <td>{{ partner.lastName }}</td>
            </tr>
            <tr>
                <th>E-Mail</th>
                <td>{{ partner.email }}</td>
            </tr>
            <tr>
                <th>Registrierung</th>
                <td>{{ partner.created }}</td>
            </tr>
            <tr>
                <th>Letzte Änderung</th>
                <td>{{ partner.updated }}</td>
            </tr>
            <tr>
                <th>Firmenname</th>
                <td>{{ partner.company.name }}</td>
            </tr>
            <tr>
                <th>Telefon</th>
                <td>{{ partner.company.phoneNumber }}</td>
            </tr>
            <tr>
                <th>IK</th>
                <td>{{ partner.company.ik }}</td>
            </tr>
            <tr>
                <th>PQ ab</th>
                <td>{{ partner.company.pq_since }}</td>
            </tr>
            <tr>
                <th>PQ bis</th>
                <td>{{ partner.company.pq_until }}</td>
            </tr>
            <tr>
                <th>Versorgungsbereiche</th>
                <td>{{ partner.company.serviceAreas }}</td>
            </tr>
            <tr>
                <th>Adresse</th>
                <td>
                    {{ partner.address.street }} <br>
                    {{ partner.address.postCode }} {{ partner.address.city }} <br>
                    {{ partner.address.country }}
                </td>
            </tr>
            <tr>
                <th>Aktiviert?</th>
                <td>{{ partner.isActivated ? 'ja' : 'nein' }}</td>
            </tr>
            <tr>
                <th>Newsletterabo</th>
                <td>{{ partner.newsletterAccepted? "ja" : "nein" }}</td>
            </tr>
        </table>
        <br>
        <md-button @click="activate()" v-show="!partner.isActivated" class="md-primary md-raised">aktivieren</md-button>
        <md-button @click="deactivate()" v-show="partner.isActivated" class="md-primary md-raised">deaktivieren</md-button>
        <md-button @click="csvExport()" v-show="claims.length > 0" class="md-primary md-raised">CSV-Export der PAWs</md-button>
        <md-button @click="deleteDialogActive = true" class="md-accent md-raised">Partner löschen</md-button>
        <br>
        <div class="md-small-size-100 md-size-80">
            <md-table v-if="claims" v-model="claims" md-sort="claimedDate" md-sort-order="desc" :md-sort-fn="customSort" md-card>
                <md-table-toolbar>
                    <h1 class="md-title">PAWs</h1>
                </md-table-toolbar>

                <md-table-empty-state
                    md-label="Nichts gefunden..."
                    md-description="Keine angenommenen PAWs zu diesem Partner gespeichert.">
                </md-table-empty-state>

                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Titel">{{ item.description }}</md-table-cell>
                    <md-table-cell md-label="Datum" md-sort-by="claimedDate">{{
                            dateFormat(item.claimedDate, "myDateTime")
                        }}
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>

        <md-dialog :md-active.sync="deleteDialogActive">
            <md-dialog-content>
                Sicher, dass dieser Partner gelöscht werden soll?!?
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-raised" v-on:click="deleteDialogActive = false">Doch nicht!</md-button>
                <md-button class="md-raised md-accent" v-on:click="deletePartner">Ja, sicher!</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import {dateFormat} from "../helpers/dates";
import {adminPawListToCSV} from "../helpers/CSV";

export default {
    name: 'Admin_Partner_detail',
    props: {},
    data: () => ({
        dateFormat,
        deleteDialogActive: false,
    }),
    mounted() {
        this.$store.dispatch("loadPartnerDetails", this.$route.params.id)
    },
    computed: {
        partner: function () {
            return this.$store.state.foreignUser
        },
        claims: function () {
            for (let company of this.$store.state.paws_claimed_byCompanies) {
                if (company.id === this.$route.params.id) {
                    return company.claimedPaws
                }
            }
            return []
        }
    },
    components: {},
    methods: {
        activate() {
            this.$store.dispatch("activatePartner", this.$route.params.id)
        },
        deactivate() {
            this.$store.dispatch("deactivatePartner", this.$route.params.id)
        },
        customSort(value) {
            return value.sort((a, b) => {
                return a["claimedDate"] < b["claimedDate"] ? 1 : -1
            })
        },       
        deletePartner(){
            this.$store.dispatch("deletePartner", this.$route.params.id).then(() => {
                // Partner deleted
                this.$store.dispatch("showSnackbar", {text: "Partner gelöscht."})
                this.$router.push("/admin/dashboard/");
            }).catch(() => {
                // Partner cannot be deleted
                this.$store.dispatch("showSnackbar", {text: "Partner konnte nicht gelöscht werden."})
            }).finally(() => {
                this.deleteDialogActive = false;
            })
         },
        csvExport(){
            adminPawListToCSV(this.claims, this.partner.company.name)
        }
    }
}
</script>

<style scoped>
#secure {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}

div#tablecontainer {
    text-align: center;
}

table#table {
    display: inline-block;
    border-collapse: collapse;
}

table#table > tr > th {
    font-size: large;
    padding: 10px;
}

table#table > tr > td {
    padding: 10px;
}

table#table > tr:nth-child(odd) {
    background: #EEE;
}

table#table > tr:nth-child(even) {
    background: #FFF;
}
</style>
